<template>
  <v-card
    v-if="relatorio.imgSalaDeOrdenha || relatorio.txtAtividadesRecomendadas"
    class="ma-4"
  >
    <v-card-title style="text-align: left">Fotos e recomendações</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="12" lg="12" xl="12" class="col-bordered">
          <span class="key-title">Atividades recomendadas: </span>
          <br />
          <div
            class="value-title"
            v-html="relatorio.txtAtividadesRecomendadas"
          ></div>
        </v-col>
        <v-col
          v-if="
            relatorio.imgSalaDeOrdenha != null &&
            relatorio.imgSalaDeOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens: </span>
          <br />
          <v-chip
            v-for="(base64, index) in relatorio.imgSalaDeOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="base64"
              :src="base64"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col cols="6" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Latitude: </span>
          <br />
          <span class="value-title">{{
            relatorio.latitude ? relatorio.latitude : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="6" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Longitude: </span>
          <br />
          <span class="value-title">{{
            relatorio.longitude ? relatorio.longitude : 'Não informado'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FotosERecomendacoes',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  created() {},

  methods: {
    renderHtmlContent() {
      return this.relatorio.txtAtividadesRecomendadas
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
