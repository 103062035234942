<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Assinaturas</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span class="key-title">
            Assinatura do
            {{
              relatorio.assProdutorOuResponsavel.name
                .split(']')[0]
                .split('[')[1]
            }}
          </span>
          <br />
          <img
            v-if="relatorio.assProdutorOuResponsavel.image"
            :src="relatorio.assProdutorOuResponsavel.image"
            alt="Não foi assinado!!!"
            width="auto"
            height="300px"
            class="assinatura"
          />
          <br />
          <span class="key-title">
            {{ relatorio.assProdutorOuResponsavel.name.split(':')[1] }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProximaVisita',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

.assinatura {
  transform: rotate(90deg);
  margin-top: -40px;
  margin-bottom: -40px;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
