import JsPDF from 'jspdf'
import { ENV_APP } from '../../../env'
import ReportConstructor from './core'
import { baseBoard } from './core/baseboard'

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório Lactaleite')

  doc.text(
    'Relatório Lactaleite',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    horaInicio,
    horaTermino,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  const dataAtendimento = _ref.dataAtendimento

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + smallGutterY + boxHeight * 2,
  )

  doc.text(consultor, startX + smallGutterX, startY + gutterY + boxHeight * 2)

  doc.text(
    'Matrícula:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + smallGutterY + boxHeight,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + gutterY + boxHeight,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )
  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    dataAtendimento || '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Horário do início:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaInicio || '-',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaTermino || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function DadosZootecnicos(doc, data, config) {
  const { boxHeight, line, startX, startY } = config

  const render = new ReportConstructor({ doc, config })

  render.renderSectionTitle({
    title: 'Dados zootécnicos',
    startX,
    startY,
  })

  render.renderMutiplesBox({
    items: [
      [
        {
          question: 'Vacas em lactação:',
          answer: data.txtVacasLactacao,
        },
        {
          question: 'Novilhas: ',
          answer: data.txtNovilhas,
        },

        {
          question: 'Vacas secas: ',
          answer: data.txtVacasSecas,
        },
      ],
      [
        {
          question: 'Bezerras:',
          answer: data.txtBezerras,
        },
        {
          question: 'Partos: ',
          answer: data.txtPartos,
        },

        {
          question: 'Secagens: ',
          answer: data.txtSecagens,
        },
      ],
      [
        {
          question: 'Inseminações:',
          answer: data.txtInseminacoes,
        },
      ],
    ],
    startX,
    startY: startY + boxHeight,
    line,
    boxHeight,
  })
}

function DadosFinanceiros(doc, data, config) {
  const { boxHeight, line, startX, startY } = config

  const render = new ReportConstructor({ doc, config })

  render.renderSectionTitle({
    title: 'Dados financeiros',
    startX,
    startY,
  })

  render.renderMutiplesBox({
    items: [
      [
        {
          question: 'Concentrados (R$):',
          answer: data.txtConcentrados,
        },
        {
          question: 'Quantidade de ração fornecida (Kg): ',
          answer: data.txtQuantidadeRacaoFornecida,
        },

        {
          question: 'Combustíveis (R$): ',
          answer: data.txtCombustiveis,
        },
      ],
      [
        {
          question: 'Energia (R$):',
          answer: data.txtEnergia,
        },
        {
          question: 'Fertilizantes (R$): ',
          answer: data.txtFertilizantes,
        },

        {
          question: 'Mão de obra (R$): ',
          answer: data.txtMaoDeObra,
        },
      ],
      [
        {
          question: 'Mão de obra (Quantidade):',
          answer: data.txtQuantidadeMaoDeObra,
        },
      ],
    ],
    startX,
    startY: startY + boxHeight,
    line,
    boxHeight,
  })
}

function fotosERecomendacao(doc, data, config) {
  const {
    boxHeight,
    line,
    startX,
    startY,
    heightLimit,
    margin,
    tableLimit,
  } = config

  const render = new ReportConstructor({ doc, config })

  render.renderSectionTitle({
    title: 'Fotos e recomendações',
    startX,
    startY,
  })

  render.renderMutiplesBox({
    items: [
      [
        {
          question: 'Latitude:',
          answer: data.latitude,
        },
        {
          question: 'Longitude: ',
          answer: data.longitude,
        },
      ],
    ],
    startX,
    startY: startY + boxHeight,
    line,
    boxHeight,
  })

  let cursor = startY + 70

  if (data.txtAtividadesRecomendadas) {
    const texts = data.txtAtividadesRecomendadas
      .split('<br>')
      .filter(res => res.length > 1)

    try {
      render.renderSectionTitle({
        title: 'Atividades recomendadas',
        startX,
        startY: cursor,
      })

      cursor += 10

      texts.forEach((text, index) => {
        cursor += config.gutterY

        doc
          .setFont('helvetica', 'normal')
          .text(text ?? ' - ', startX + config.smallGutterX, cursor, {
            maxWidth: 500,
          })

        const textSize = doc.getTextDimensions(text ?? ' - ', {
          maxWidth: 500,
        })

        cursor += (Math.round(textSize.h / 10) - 1) * config.smallGutterY

        if (cursor + 30 > heightLimit) {
          doc.addPage()
          cursor = margin
        }

        cursor = ReportConstructor.checkAddPage({
          doc,
          config,
          cursor,
          area: config.smallBoxHeight,
        })

        if (index === texts.length - 1) {
          cursor += 20
        }
      })

      if (data.imgSalaDeOrdenha) {
        data.imgSalaDeOrdenha.forEach(img => {
          if (cursor + 300 > heightLimit) {
            doc.addPage()
            cursor = margin
          }

          render.renderSectionTitle({
            title: 'Imagens: ',
            startX,
            startY: cursor,
          })

          doc.addImage(
            img,
            'PNG',
            tableLimit / 2 - 150,
            cursor + 20,
            250,
            250,
            '',
            'MEDIUM',
          )

          cursor += 200
        })
      }
    } catch (err) {
      throw new Error('Erro ao gerar relatório')
    }

    return cursor
  }
}

function assinaturas(doc, relatorio, config, cursor) {
  const {
    tableLimit,
    line,
    boxHeight,
    smallGutterY,
    heightLimit,
    margin,
  } = config

  const tipo = relatorio.assProdutorOuResponsavel.name
    .split(':')[0]
    .replace('[', '')
    .replace(']', '')
  const name = relatorio.assProdutorOuResponsavel.name.split(':')[1]

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += boxHeight * 2

  const signature = new Image()

  if (
    relatorio.assProdutorOuResponsavel &&
    relatorio.assProdutorOuResponsavel.image
  ) {
    signature.src = relatorio.assProdutorOuResponsavel.image

    doc.text(name, tableLimit / 2, cursor + 125, null, null, 'center')

    doc.text(
      `Assinatura do ${tipo}(a)`,
      tableLimit / 2,
      cursor + 130 + smallGutterY,
      null,
      null,
      'center',
    )

    doc.rect(tableLimit / 4, cursor + 115, tableLimit / 2, line, 'F')

    doc.addImage(
      signature,
      'PNG',
      tableLimit / 2 - 80,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  return cursor
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95

    const doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })

    const config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      let cursor = 0

      header(doc, config, data)
      subHeader(doc, data, config)

      config.startY = config.startY + config.boxHeight + 120

      cursor = config.startY

      cursor = DadosZootecnicos(doc, data, config, cursor)

      config.startY += 120

      cursor = DadosFinanceiros(doc, data, config, cursor)

      config.startY += 120

      cursor = fotosERecomendacao(doc, data, config, cursor)

      cursor = assinaturas(doc, data, config, cursor)

      // cursor = assinaturas(doc, data, config, cursor)
      config.startY = cursor + config.boxHeight - 3
      const pageCount = doc.internal.getNumberOfPages()

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        baseBoard(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-lactaleite-${data.fazenda.pessoajuridica.NomeFantasia}-${data.dataAtendimento}.pdf`,
          {
            returnPromise: true,
          },
        )
        .then(() => {
          return resolve()
        })
    } catch (err) {
      reject(err)
    }
  })
}
