<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Dados financeiros</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Concentrados (R$): </span>
          <span class="value-title">
            {{
              relatorio.txtConcentrados
                ? relatorio.txtConcentrados
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Quantidade de ração fornecida (Kg): </span>
          <span class="value-title">{{
            relatorio.txtQuantidadeRacaoFornecida
              ? relatorio.txtQuantidadeRacaoFornecida
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Combustíveis (R$): </span>
          <span class="value-title">{{
            relatorio.txtCombustiveis
              ? relatorio.txtCombustiveis
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Energia (R$): </span>
          <br />
          <span class="value-title">{{
            relatorio.txtEnergia ? relatorio.txtEnergia : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Fertilizantes (R$): </span>
          <br />
          <span class="value-title">{{
            relatorio.txtFertilizantes
              ? relatorio.txtFertilizantes
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Mão de obra (R$): </span>
          <br />
          {{ relatorio.txtMaoDeObra }}
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Mão de obra (Quantidade): </span>
          <br />
          {{ relatorio.txtQuantidadeMaoDeObra }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
