<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Dados zootécnicos</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" class="col-bordered">
          <span class="key-title">Vacas em lactação: </span>
          <span class="value-title">
            {{
              relatorio.txtVacasLactacao
                ? relatorio.txtVacasLactacao
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Vacas secas: </span>
          <span class="value-title">{{
            relatorio.txtVacasSecas ? relatorio.txtVacasSecas : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Novilhas: </span>
          <span class="value-title">{{
            relatorio.txtNovilhas ? relatorio.txtNovilhas : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Bezerras: </span>
          <br />
          <span class="value-title">{{
            relatorio.txtBezerras ? relatorio.txtBezerras : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Partos: </span>
          <br />
          <span class="value-title">{{
            relatorio.txtPartos ? relatorio.txtPartos : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Secagens: </span>
          <br />
          {{ relatorio.txtSecagens }}
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Inseminações: </span>
          <br />
          {{ relatorio.txtInseminacoes }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    calcProdutividade() {
      if (this.relatorio.NumeroMDO && this.relatorio.ProducaoMedia) {
        return `${convertNumberBR(
          parseInt(this.relatorio.ProducaoMedia) /
            parseInt(this.relatorio.NumeroMDO),
        )}`
      } else {
        return '-'
      }
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
